@use "sass:color";
// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables-v2';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

@import 'table-stacked';


html {
    height: var(--100vh, 100vh);
}
body,
#root {
    height: 100%;
    // color: $gold;
    overflow: auto;
}

body {
    // @include gradient-y-three-colors($tgl-dark, $red, 50%, $tgl-dark);
    // background: $input-bg linear-gradient(180deg, $input-bg, 35%, $input-bg, 80%, mix($body-bg, $input-bg, 45%)) repeat-x;

    color: $white;
}

.scrollable {
    overflow: auto;
    // max-height: 100vh;
}

@each $value in $overflows {
    .overflow-y-#{$value} {
        overflow-y: $value !important;
    }

    .overflow-x-#{$value} {
        overflow-x: $value !important;
    }
}

// .btn {
//     border: $btn-border-width solid $gold;
//     color: $tgl;
// }

@each $color,
$value in $theme-colors {
    .btn-gradient-#{$color} {
        @include button-variant($value, $value);

        @if $enable-gradients {
            background: $value linear-gradient(180deg, $value, 25%, $value, 60%, mix($body-bg, $value, 45%)) repeat-x;
        }

        @else {
            background-color: $value;
        }

        color: color-yiq($value);
    }

    .bg-gradient-tgl-#{$color} {
        background: $value linear-gradient(180deg, $value, 25%, $value, 60%, mix($body-bg, $value, 45%)) repeat-x;

        // color: mix($tgl, color-yiq($value), 50%);
    }

    .bg-gradient-tgl-diagonal-#{$color} {
        background: $value linear-gradient(to bottom right, $value, 25%, $value, 60%, mix($body-bg, $value, 45%)) repeat-x;

        // color: mix($tgl, color-yiq($value), 50%);
    }

    .thead-gradient-#{$color} {
        @if $enable-gradients {
            background: $value linear-gradient(180deg, $value, 25%, $value, 60%, mix($body-bg, $value, 45%)) repeat-x;
        }

        @else {
            background-color: $value;
        }

        color: color-yiq($value);
    }

    @if not $enable-gradients {

        @each $color,
        $value in $theme-colors {
            @include bg-gradient-variant(".bg-gradient-#{$color}", $value, true);
        }
    }
}

.input-group-text {
    color: #999;
    border-color: $input-border-color;

    @if $enable-gradients {
        background: #272727 linear-gradient(180deg, #272727, 25%, #272727, 60%, mix($body-bg, #272727, 45%)) repeat-x;
    }

    @else {
        background-color: #272727;
    }
}

.form-control {

    &:disabled,
    &[readonly] {
        color: $input-disabled-color;
    }
}

.card {
    @if $enable-gradients {
        background: $card-bg linear-gradient(to bottom right, $card-bg, 35%, $card-bg, 60%, mix($body-bg, $card-bg, 45%)) repeat-x;
    }

    @else {
        background-color: $card-bg;
    }
}

.modal-content {
    @if $enable-gradients {
        background: $modal-bg linear-gradient(to bottom right, $modal-bg, 35%, $modal-bg, 60%, mix($body-bg, $modal-bg, 45%)) repeat-x;
    }

    @else {
        background-color: $modal-bg;
    }
}

.form-control {
    @if $enable-gradients {
        background: $input-bg linear-gradient(180deg, $input-bg, 35%, $input-bg, 80%, mix($body-bg, $input-bg, 45%)) repeat-x;
    }

    @else {
        background-color: $input-bg;
    }

    &:disabled,
    &[readonly] {
        @if $enable-gradients {
            background: $input-disabled-bg linear-gradient(180deg, mix($body-bg, $input-disabled-bg, 15%), 35%, mix($body-bg, $input-disabled-bg, 15%), 60%, mix($body-bg, $input-disabled-bg, 45%)) repeat-x;
        }

        @else {
            background-color: $input-disabled-bg;
        }

        border-color: darken($input-border-color, 10%);
    }

    border-color: $input-border-color;
}

.nav-tabs {
    .nav-link {
        color: $gold;
        border: $nav-tabs-border-width solid darken($gold, 20%);
        @include border-top-radius($nav-tabs-border-radius);

        @include hover-focus() {
            border-color: $nav-tabs-link-hover-border-color;
        }

        &.disabled {
            color: $nav-link-disabled-color;
            background-color: transparent;
            border-color: transparent;
        }
    }

    border-bottom-color: $gold;

    .nav-link.active,
    .nav-item.show .nav-link {
        color: $gold;
        border-color: $gold;
        border-bottom-color: $tgl-dark;

        @if $enable-gradients {
            background: $primary linear-gradient(180deg, $primary, 25%, $primary, 60%, mix($body-bg, $primary, 75%)) repeat-x;
        }

        @else {
            background-color: $primary;
        }

        // @include gradient-y($tgl, $tgl-dark);
    }
}

.nav-pills {

    .nav-link.active,
    .show>.nav-link {
        color: $gold;
        border: 1px solid $gold;

        @if $enable-gradients {
            background: $primary linear-gradient(180deg, $primary, 25%, $primary, 60%, mix($body-bg, $primary, 75%)) repeat-x;
        }

        @else {
            background-color: $primary;
        }
    }
}

.Toastify__toast {
    background-color: $tgl-dark;

    &--default {
        color: $rt-color-default;
    }

    &--info {
        color: $rt-color-info;
    }

    &--success {
        color: $rt-color-success;
    }

    &--warning {
        color: $rt-color-warning;
    }

    &--error {
        color: $rt-color-error;
    }
}