@use "sass:color";

$tgl-dark: #191919;
$tgl: #333541;
$tgl-light: #aaaaaa;

// Typography
$font-family-sans-serif: 'Nunito',
sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

// Colors
$blue: #072b49;
$indigo: #222747;
$purple: #2f0e5f;
$pink: #6d062a;
$red: #700704;
$orange: #6e3b05;
$yellow: #978608;
$green: #065c2a;
$teal: #1b413d;
$cyan: #203546;
$gold: gold;
$silver: silver;
$white: white;
$black: black;

$dark: #121212;
// $light: #AEF6C7;

// Body
// $body-bg: #f8fafc;
// $body-bg: #22031F;
$body-bg: $dark;
$body-color: $tgl-dark;

// $tgl-red2: color.scale($tgl-red, $saturation: -50%);
// $tgl-fancy-red: linear-gradient($tgl-red, $tgl-dark-red);
$enable-gradients: false;
$enable-shadows: true;

$primary: $tgl;
$secondary: lighten($tgl, 0.5);

$theme-colors: (
    'primary': $primary,
    'secondary': $secondary,
    'gold': $gold,
    'silver': $silver,
    'white': $white,
    'black': $black,
    // 'tgl-primary': $tgl-red,
    // 'secondary': $tgl-red2,
);

$input-color: #000000;
$input-bg: #f2F2F2;
$input-disabled-color: #999;
$input-disabled-bg: #333333;
$input-border-color: $primary;
// $input-group-addon-bg: $primary;
// $input-group-addon-border-color: $primary;
// $input-group-addon-color: $secondary;

$card-bg: $secondary;
// $card-bg: $tgl-dark;

$modal-bg: lighten($body-bg, .2);

$border-color: #3C3C3C;
$card-border-color: rgba($border-color, .5);
// // $table-bg: darken($body-bg, .1);
$table-color: $white;
// $table-stacked-heading-width: 30%;
// $table-stacked-gap: 6px;

// $table-head-color: $tgl-dark;
// $table-head-bg: $primary;
// $table-border-color: $primary;

// // $table-dark-color: $tgl-dark-red;
// // $table-dark-bg: $gold;
// // $table-dark-border-color: $gold;

$nav-tabs-link-hover-border-color: $gold;
// // $navbar-light-color: $tgl-red;
// // $navbar-dark-color: $gold;
// $yiq-text-dark: $secondary;
// $yiq-text-light: $primary;

$navbar-dark-color: rgba($gold, .5) !default;
$navbar-dark-hover-color: rgba($gold, .75) !default;
$navbar-dark-active-color: $gold !default;
$navbar-dark-disabled-color: rgba($gold, .25) !default;
$navbar-dark-toggler-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path stroke='#{$navbar-dark-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>") !default;
$navbar-dark-toggler-border-color: rgba($gold, .1) !default;

$navbar-light-color: rgba($gold, .5) !default;
$navbar-light-hover-color: rgba($gold, .7) !default;
$navbar-light-active-color: rgba($gold, .9) !default;
$navbar-light-disabled-color: rgba($gold, .3) !default;
$navbar-light-toggler-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path stroke='#{$navbar-light-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>") !default;
$navbar-light-toggler-border-color: rgba($gold, .1) !default;

$navbar-light-brand-color: $navbar-light-active-color !default;
$navbar-light-brand-hover-color: $navbar-light-active-color !default;
$navbar-dark-brand-color: $navbar-dark-active-color !default;
$navbar-dark-brand-hover-color: $navbar-dark-active-color !default;

$link-color: $gold;

$button-border-color: $border-color;

// $dropdown-bg:                       #272727;
// $custom-select-padding-y:           $input-padding-y !default;
// $custom-select-padding-x:           $input-padding-x !default;
// $custom-select-font-family:         $input-font-family !default;
// $custom-select-font-size:           $input-font-size !default;
// $custom-select-height:              $input-height !default;
// $custom-select-indicator-padding:   1rem !default; // Extra padding to account for the presence of the background-image based indicator
// $custom-select-font-weight:         $input-font-weight !default;
// $custom-select-line-height:         $input-line-height !default;
// $custom-select-color:               #999;
// $custom-select-disabled-color:      $gray-600 !default;
// $custom-select-bg:                  #272727;
// $custom-select-disabled-bg:         $gray-200 !default;
// $custom-select-bg-size:             8px 10px !default; // In pixels because image dimensions
// $custom-select-indicator-color:     $gray-800 !default;
// $custom-select-indicator:           url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'><path fill='#{$custom-select-indicator-color}' d='M2 0L0 2h4zm0 5L0 3h4z'/></svg>") !default;
// $custom-select-background:          escape-svg($custom-select-indicator) right $custom-select-padding-x center / $custom-select-bg-size no-repeat !default; // Used so we can have multiple background elements (e.g., arrow and feedback icon)

// $custom-select-feedback-icon-padding-right: add(1em * .75, (2 * $custom-select-padding-y * .75) + $custom-select-padding-x + $custom-select-indicator-padding) !default;
// $custom-select-feedback-icon-position:      center right ($custom-select-padding-x + $custom-select-indicator-padding) !default;
// $custom-select-feedback-icon-size:          $input-height-inner-half $input-height-inner-half !default;

// $custom-select-border-width:        $input-border-width !default;
// $custom-select-border-color:        $input-border-color !default;
// $custom-select-border-radius:       $border-radius !default;
// $custom-select-box-shadow:          inset 0 1px 2px rgba($black, .075) !default;

// $custom-select-focus-border-color:  $input-focus-border-color !default;
// $custom-select-focus-width:         $input-focus-width !default;
// $custom-select-focus-box-shadow:    0 0 0 $custom-select-focus-width $input-btn-focus-color !default;

// $custom-select-padding-y-sm:        $input-padding-y-sm !default;
// $custom-select-padding-x-sm:        $input-padding-x-sm !default;
// $custom-select-font-size-sm:        $input-font-size-sm !default;
// $custom-select-height-sm:           $input-height-sm !default;

// $custom-select-padding-y-lg:        $input-padding-y-lg !default;
// $custom-select-padding-x-lg:        $input-padding-x-lg !default;
// $custom-select-font-size-lg:        $input-font-size-lg !default;
// $custom-select-height-lg:           $input-height-lg !default;


// // React Toast
// $rt-toast-background: $tgl-dark;

$rt-color-default: #fff !default;
$rt-color-dark: #121212 !default;
$rt-color-info: #3498db !default;
$rt-color-success: #07bc0c !default;
$rt-color-warning: #f1c40f !default;
$rt-color-error: #e74c3c !default;

$rt-text-color-default: #aaa !default;
$rt-text-color-dark: #fff !default;

$pagination-color: $link-color !default;
$pagination-bg: $primary !default;
$pagination-hover-bg: mix($pagination-bg, $gold, 50%) !default;
$pagination-active-bg: mix($pagination-bg, $white, 50%) !default;
$pagination-active-color: $white !default;
$pagination-disabled-bg: $primary !default;
// $pagination-border-color: $gray-300 !default;

// $custom-control-indicator-checked-color: $gold !default;
// $custom-control-indicator-checked-border-color: $gold !default;
// $custom-control-indicator-checked-bg: $gold !default;
// $custom-control-indicator-checked-box-shadow:
// $custom-control-indicator-focus-box-shadow:
// $custom-control-indicator-focus-border-color
// $custom-control-indicator-active-color: $primary !default;
// $custom-control-indicator-active-bg: $gold !default;
// $custom-control-indicator-focus-bg: $gold !default;
