$table-stacked-heading-width: 30% !default;
$table-stacked-gap: 6px !default;

.table {
    &.table-stacked {
        @each $breakpoint in map-keys($grid-breakpoints) {
            $next: breakpoint-next($breakpoint, $grid-breakpoints);
            $infix: breakpoint-infix($next, $grid-breakpoints);

            &#{$infix} {
                @include media-breakpoint-down($breakpoint) {
                    display: block;
                    width: 100%;

                    // Convert to blocks when stacked
                    >caption,
                    >tbody,
                    >tbody>tr,
                    >tbody>tr>td,
                    >tbody>tr>th {
                        display: block;
                    }

                    // Hide when stacked
                    >thead,
                    >tfoot {
                        display: none;

                        >tr.b-table-top-row,
                        >tr.b-table-bottom-row {
                            display: none;
                        }
                    }

                    // Caption positioning
                    >caption {
                        caption-side: top !important;
                    }

                    >tbody {
                        // overflow-y: auto;
                        // max-height: calc(100vh - 300px);

                        >tr {
                            position: relative;
                            display: flex;
                            flex-direction: column;
                            min-width: 0; // See https://github.com/twbs/bootstrap/pull/22740#issuecomment-305868106
                            height: $card-height;
                            word-wrap: break-word;
                            background: $card-bg linear-gradient(180deg, $card-bg, 25%, $card-bg, 60%, mix($body-bg, $card-bg, 45%)) repeat-x;
                            ;
                            background-clip: border-box;
                            border: $card-border-width solid $card-border-color;
                            @include border-radius($card-border-radius);

                            >hr {
                                margin-right: 0;
                                margin-left: 0;
                            }

                            >.list-group {
                                border-top: inherit;
                                border-bottom: inherit;

                                &:first-child {
                                    border-top-width: 0;
                                    @include border-top-radius($card-inner-border-radius);
                                }

                                &:last-child {
                                    border-bottom-width: 0;
                                    @include border-bottom-radius($card-inner-border-radius);
                                }
                            }

                            // Due to specificity of the above selector (`.card > .list-group`), we must
                            // use a child selector here to prevent double borders.
                            >.card-header+.list-group,
                            >.list-group+.card-footer {
                                border-top: 0;
                            }

                            .card-body {
                                // Enable `flex-grow: 1` for decks and groups so that card blocks take up
                                // as much space as possible, ensuring footers are aligned to the bottom.
                                flex: 1 1 auto;
                                // Workaround for the image size bug in IE
                                // See: https://github.com/twbs/bootstrap/pull/28855
                                min-height: 1px;
                                padding: $card-spacer-x;
                                color: $card-color;
                            }

                            // Turn cells with labels into micro-grids
                            >[data-label] {

                                // Cell header label pseudo element
                                &::before {
                                    content: attr(data-label);
                                    width: $table-stacked-heading-width;
                                    float: left;
                                    text-align: left;
                                    overflow-wrap: break-word;
                                    font-weight: bold;
                                    font-style: normal;
                                    padding: 0 calc(#{$table-stacked-gap} / 2) 0 0;
                                    margin: 0;
                                    color: gold
                                }

                                // Add clearfix in-case field label wraps
                                &::after {
                                    display: block;
                                    clear: both;
                                    content: "";
                                }

                                // Cell value (we wrap the cell value in a div when stacked)
                                >div {
                                    display: inline-flex;
                                    width: calc(100% - #{$table-stacked-heading-width});
                                    // Add "gap" between "cells"
                                    padding: 0 0 0 calc(#{$table-stacked-gap} / 2);
                                    margin: 0;
                                }
                            }

                            // Dont show the fixed top/bottom rows
                            &.top-row,
                            &.bottom-row {
                                display: none;
                            }

                            >th,
                            >td {
                                border-color: $card-border-color;
                                line-height: 2.5em;
                            }

                        }
                        tr + tr {
                            margin-top: 1em;
                        }
                    }
                }
            }
        }
    }
}